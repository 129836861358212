/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Sabía que la empresa Microchip mandaba muestras gratuitas de sus productos a quien las pedía. Para esto solo hace falta un correo que no sea el típico de hotmail, gmail, etc yo utilicé el de la facultad. Podéis encargarlos es esta pagina ", React.createElement(_components.a, {
    href: "http://sample.microchip.com/Default.aspx?testCookies=true"
  }, "http://sample.microchip.com/Default.aspx?testCookies=true"), "."), "\n", React.createElement(_components.p, null, "Se pueden pedir hasta cuatro muestra así que yo me pedí cuatro PICs, pensaba que tardarian mucho pero la verdad es que en menos de dos semanas llegaron."), "\n", React.createElement(_components.p, null, "Aunque aun no se utilizarlos por lo menos ya los tengo para cuando aprenda, ademas así he aprendido que los que pida tienen que terminar en /P y no en /SD que son de montaje superficial, menos mal que solo tengo uno así. Bueno esto es todo."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
